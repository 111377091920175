<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="标题" prop="title">
					<el-input v-model="searchForm.title" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item label="作者" prop="author">
					<el-input v-model="searchForm.author" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="getList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" cell-class-name="text-center"
				header-cell-class-name="header-row-class-name">
				<el-table-column prop="id" label="ID" min-width="60px"></el-table-column>
				<el-table-column prop="title" label="标题" min-width="120px">
					<template slot-scope="{row}">
						<el-popover placement="top" trigger="click">
							<div class="box-card">
								{{row.title}}
							</div>
							<div class="line-clamp-1 pointer" slot="reference">
								{{row.title}}
							</div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="author" label="作者" min-width="120px"></el-table-column>
				<el-table-column prop="cover" label="封面" min-width="60px">
					<template slot-scope="{row}">
						<el-image style="width: 40px; height: 40px" :src="row.cover" :preview-src-list="[row.cover]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="sort" min-width="160px" label="排序值"></el-table-column>
				<el-table-column prop="intro" min-width="160px" label="简介">
					<template slot-scope="{row}">
						<el-popover placement="top" trigger="click">
							<div class="box-card">
								{{row.intro}}
							</div>
							<div class="line-clamp-1 pointer" slot="reference">
								{{row.intro}}
							</div>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
				<el-table-column width="200" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<el-button class="el-icon-delete cur-p" type="primary" size="mini" @click="del(row)">
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="`${editId?'编辑':'创建'}`" :visible.sync="showEdit" width="60%">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="editForm" :rules="getRules" label-width="80px">
					<el-form-item label="文章标题" prop="title">
						<el-input type="textarea" placeholder="请输入文章标题" v-model="editForm.title"></el-input>
					</el-form-item>
					<el-form-item label="文章封面" prop="cover">
						<el-upload class="avatar-uploader" action="/admin/upload" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="editForm.cover" :src="editForm.cover" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							<div class="el-upload__tip" slot="tip">750*400</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="文章作者" prop="author">
						<el-input v-model="editForm.author" placeholder="请输入文章作者"></el-input>
					</el-form-item>
					<el-form-item label="文章简介" prop="intro">
						<el-input type="textarea" placeholder="请输入文章简介" v-model="editForm.intro"></el-input>
					</el-form-item>
					<el-form-item label="文章内容" prop="content">
						<RichText v-model="editForm.content" :type="1" placeholder="请输入文章内容"></RichText>
					</el-form-item>
					<el-form-item label="排序值" prop="sort">
						<el-input type="number" placeholder="请输入排序值,排序值越大越靠前" v-model="editForm.sort"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :loading="creating" @click="edit" v-if="editId"
							style="margin: 10px auto">编辑</el-button>
						<el-button type="primary" :loading="creating" @click="add" v-else style="margin: 10px auto">创建
						</el-button>
						<el-button @click="showEdit=false" style="margin: 10px">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="视频上传" :visible.sync="upDialog" width="50%">
			<el-upload class="upload-demo" action="/admin/uploadVideo" ref="uploadVideo"
				:show-file-list="false"
				:data="{is_editor:2}"
				:on-success="(response, file, fileList)=>handleSuccess(response, file, fileList)" :fileList='fileList'
				:on-error="handleError">
				<el-button size="small" type="primary">点击上传</el-button>
			</el-upload>
		</el-dialog>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'


	export default {
		name: "article",
		components: {
			RichText
		},
		data() {
			return {
				loading: false,
				creating: false,
				total: 0,
				showEdit: false,

				searchForm: {
					page: 1,
					limit: 10,
					title: '', //标题
					author: '', //作者
				},
				editForm: {
					title: '', //标题
					intro: '', //简介
					author: '', //作者
					content: '', //内容
					sort: '', //排序值
					cover: '', //封面
				},
				editId: '',
				tableData: [],
				upDialog:false,
				fileList:[],
			}
		},
		created() {
			this.getList();
			this.$event.$on('upVideo', () => {
				console.log("22222")
				this.upDialog = true
			})
			this.$event.$on('closeUp', () => {
				this.upDialog = false
			})
		},
		computed: {
			getRules() {
				return {
					title: [{
						required: true,
						message: `标题不能为空`,
						trigger: ['blur', 'change']
					}],
					author: [{
						required: true,
						message: `作者不能为空`,
						trigger: ['blur', 'change']
					}],
					content: [{
						required: true,
						message: `内容不能为空`,
						trigger: ['blur', 'change']
					}],
					sort: [{
						required: true,
						message: `排序值不能为空`,
						trigger: ['blur', 'change']
					}],
					cover: [{
						required: true,
						message: `封面不能为空`,
						trigger: ['blur', 'change']
					}],
					intro: [{
						required: true,
						message: `简介不能为空`,
						trigger: ['blur', 'change']
					}],

				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.getList();
			},
		},
		methods: {
			//上传视频成功
			handleSuccess(res, file, fileList) {
				// console.log(file.response.url);
				if (res.code==200) {
					this.$event.$emit('setVideo', res.url)
					this.upDialog = false
					// this.$message.success('上传成功！')
				} else {
					this.$message.error(res.msg)
				}
			},
			handleError(error){
				console.log(error)
				this.$message.error(error)
			},
			//头像
			handleAvatarSuccess(res, file) {
				this.editForm.cover = res.url;
			},

			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				//
				// if (!isJPG) {
				//     this.$message.error('上传头像图片只能是 JPG,png 格式!');
				// }
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isLt2M;
			},
			
			handShowAdd() {
				this.editId = '';
				try {
					this.showEdit = true;
					this.$refs['forms'].resetFields()
					for (let i in this.editForm) {
						this.editForm[i] = '';
					}
				} catch {}
			},
			async handEditShow(row) {
				this.editId = row.id;
				let [e, data] = await this.$api.getArticleOneApi({
					id: row.id
				});
				if (e) return;
				if (data.code === 200) {
					for (let i in this.editForm) {
						this.editForm[i] = data.data[i];
					}
					this.showEdit = true;
				}

			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//获取列表
			async getList() {
				this.loading = true
				let [e, data] = await this.$api.getArticleListApi(this.searchForm);
				this.loading = false
				if (e) return;
				if (data.code === 200) {
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
			@delToast()
			async del({
				id
			}) {
				let [e, data] = await this.$api.delArticleApi({
					id
				});
				if (e) return;
				if (data.code === 200) {
					this.getList();
					this.$message.success('删除成功')
				} else {
					this.$message.warning(`${data.msg}`)
				}
			},


			//添加
			@formVerify('forms')
			async add() {
				this.creating = true;
				let [e, data] = await this.$api.addArticleApi(this.editForm);
				if (e) {
					this.creating = false;
					return;
				}
				if (data.code === 200) {
					this.getList();
					this.showEdit = false;
					this.creating = false;
					this.$message.success('添加成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			},
			//编辑
			@formVerify('forms')
			async edit() {
				this.creating = true;
				let [e, data] = await this.$api.editArticleApi({
					id: this.editId,
					...this.editForm
				});
				if (e) {
					this.creating = false;
					return;
				}
				if (data.code === 200) {
					this.showEdit = false;
					this.creating = false;
					this.getList();
					this.$message.success('保存成功')
				} else {
					this.creating = false;
					this.$message.warning(`${data.msg}`)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	/deep/ .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.bm-view {
		width: 800px;
		height: 400px;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
</style>
